import {
  InfoGrey,
  Chevron,
  Download,
  Search,
  Trash,
  Refresh,
  PhoneCall,
} from "assets/images";
import AddNewContact from "components/AddNewContact";
import ContactDetails from "components/ContactDetails";
import ImportCSV from "components/ImportCSV";
import React, { useState, useEffect, useContext } from "react";
import { useGetContactList } from "hooks";
import { Avatar, Spinner } from "components";
import { formatPhoneNumber } from "shared/resources";
import useDeleteContact from "hooks/useDeleteContact";
import { SipSocketContext } from "hooks/useSip/SipSocketContext";
import axios from "axios";
import { routes } from "api/routes";
import { getToken } from "shared/resources";
import { toastEmitter } from "components/Toast";

function Contacts() {
  const [showAddOptions, setAddOptions] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCSVModal, setCSVModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [activeContact, setActiveContact] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const { data, isError, mutate } = useGetContactList();
  const { mutateAsync: deleteContactMutate } = useDeleteContact();

  const { socket = {} } = useContext(SipSocketContext);
  const { _makeCall = () => null } = socket;

  useEffect(() => {
    mutate({
      page,
      limit: 100,
      search,
    });
  }, [mutate, search, page]);

  const handleContactDetails = (contact) => {
    setActiveContact(contact);
    setAddOptions(false);
  };

  const handleDetailsModalClose = () => {
    setActiveContact(null);
  };

  const handleAddOptions = () => {
    setAddOptions(!showAddOptions);
  };

  const handleContactModalClose = () => {
    setShowModal(false);
  };

  const handleDeleteModal = () => {
    setDeleteModal(true);
    setAddOptions(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  const handleCSVModalClose = () => {
    setCSVModal(false);
  };

  const handleCreateNewContact = () => {
    setShowModal(true);
    setAddOptions(false);
  };

  const handleImportCSV = () => {
    setCSVModal(true);
    setAddOptions(false);
  };

  if (isError) {
    return <p>Error loading contacts</p>;
  }

  const contactList =
    data && data?.data
      ? data?.data?.data.reduce((prev, cur) => {
          const key = cur.first_name.charAt(0).toUpperCase();
          if (!prev[key]) {
            prev[key] = [];
          }
          prev[key].push(cur);
          return prev;
        }, {})
      : null;

  return (
    <div className={`px-2 mx-auto max-w-[1440px] py-8`}>
      <div className="flex justify-between border-b border-black/20 pb-6">
        <div>
          <p className="font-bold text-[20px]">Contacts</p>
          <p className={`pt-1 text-[15px] font-[400]`}>
            Manage your contacts here
          </p>
        </div>
        <button
          onClick={handleAddOptions}
          className="flex gap-1 relative bg-[#4F772D] w-[100px] h-[40px] rounded-md outline-none justify-center items-center text-white"
        >
          + Add
          <Chevron />
        </button>
        {showAddOptions && (
          <div className="absolute top-[160px] z-40 right-12 rounded-md shadow-lg bg-white w-[180px] text-[14px] flex flex-col gap-2 py-2 ">
            <button
              onClick={handleCreateNewContact}
              className="hover:bg-black/30 duration-300 hover:bg-opacity-10 py-3 text-left px-4"
            >
              Create new contact
            </button>
            <button
              onClick={handleImportCSV}
              className="hover:bg-black/30 duration-300 hover:bg-opacity-10 py-3 text-left px-4"
            >
              Import CSV file
            </button>
          </div>
        )}
      </div>

      <section>
        <div className="flex gap-6 border-b border-black/20 py-6">
          <button
            onClick={async (event) => {
              event.preventDefault();
              setLoading(true);
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_BASE_API_URL}${routes.EXPORT_CONTACT.URL}`,
                  {},
                  {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                      Authorization: `Bearer ${getToken()}`,
                    },
                    responseType: "blob",
                  }
                );
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = "fonu-contacts.csv.gz";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              } catch (error) {
                if (error?.response?.status === 401) {
                  localStorage.clear();
                  sessionStorage.clear();
                  return (window.location.href = "/");
                } else {
                  toastEmitter("error", error?.response?.data?.error?.message);
                }
              }
              setLoading(false);
            }}
            className="flex gap-1 relative border border-black/20 text-[14px] w-[170px] h-[40px] rounded-md outline-none justify-center items-center text-black"
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Download />
                Export CSV
              </>
            )}
          </button>
          <div className="w-full">
            <input
              type="text"
              value={search}
              onChange={(event) => {
                setPage(1);
                setSearch(event.target.value);
              }}
              placeholder="Search by name or phone number"
              className="w-[100%] relative rounded-md outline-none border-none bg-black/10 focus:border-black/10 pl-12 text-[14px]"
            />
            <Search className="absolute mt-[-30px] ml-3" />
          </div>
        </div>
      </section>
      <section className="flex items-center gap-6 my-4">
        <input
          type="checkbox"
          checked={selectedContacts.length === (data?.data?.data?.length || 0)}
          onChange={() => {
            selectedContacts.length === data?.data?.data?.length
              ? setSelectedContacts([])
              : setSelectedContacts(data?.data?.data?.map((d) => d.uuid));
          }}
          className="checked:bg-[#4F772D] rounded-sm focus:bg-[#4F772D] cursor-pointer"
        />
        <button className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-[#4F772D] hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md">
          <PhoneCall className="h-[20px] w-[20px]" />
          Bulk Dial
        </button>
        <button
          onClick={handleDeleteModal}
          className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-danger hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
        >
          <Trash className="h-[20px] w-[20px]" />
          Delete
        </button>
        <button
          onClick={() => {
            mutate({
              page: 1,
              limit: 100,
              search,
            });
          }}
          className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-black/50 hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
        >
          <Refresh className="h-[20px] w-[20px]" />
          Refresh
        </button>
      </section>

      <section className="my-8">
        {!contactList ? (
          <div>
            <Spinner />
          </div>
        ) : (
          Object.entries(contactList).map(([category, contacts]) => {
            return (
              <div key={category} className="flex gap-6">
                <h2 className="font-bold mt-[57px] text-[18px]">{category}</h2>
                <div className="w-full mt-5">
                  {contacts.map((contact, index) => (
                    <div
                      key={index}
                      className="flex gap-4 items-center pt-2 my-3 hover:bg-black/20 hover:px-3 rounded-md cursor-pointer duration-500"
                    >
                      <input
                        type="checkbox"
                        checked={selectedContacts.includes(contact.uuid)}
                        onChange={() => {
                          const contactIndex = selectedContacts.findIndex(
                            (c) => c === contact.uuid
                          );
                          if (contactIndex !== -1) {
                            const clonedContacts = [...selectedContacts];
                            clonedContacts.splice(contactIndex, 1);
                            setSelectedContacts(clonedContacts);
                          } else {
                            setSelectedContacts([
                              ...selectedContacts,
                              contact.uuid,
                            ]);
                          }
                        }}
                        className="checked:bg-[#4F772D] rounded-sm focus:bg-[#4F772D] cursor-pointer"
                      />
                      <div className="flex border-b border-black/20 items-center w-full gap-4">
                        <div
                          onClick={() => handleContactDetails(contact)}
                          className="flex justify-between w-full items-center"
                        >
                          <div className="flex gap-2 pb-3 w-full items-center">
                            <Avatar
                              name={`${contact?.first_name} ${
                                contact?.last_name ?? ""
                              }`}
                            />
                            <div className="flex flex-col gap-1">
                              <p className="text-[16px] font-semibold">
                                {contact.first_name} {contact.last_name}
                              </p>

                              <p className="text-[14px] font-[400]">
                                {formatPhoneNumber(contact.phone)}
                              </p>
                            </div>
                          </div>
                          <div>
                            <InfoGrey className="text-[#4F772D] cursor-pointer" />
                          </div>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            _makeCall(contact.phone, contact.phone);
                          }}
                        >
                          <PhoneCall className="text-[#4F772D] cursor-pointer" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })
        )}
      </section>

      {activeContact && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleDetailsModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[500px] h-[500px] overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <ContactDetails
              handleDetailsModalClose={handleDetailsModalClose}
              contact={activeContact}
            />
          </div>
        </div>
      )}

      {showModal && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleContactModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[500px] h-[500px] overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <AddNewContact
              handleContactModalClose={handleContactModalClose}
              mutate={mutate}
            />
          </div>
        </div>
      )}
      {showCSVModal && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleCSVModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[500px] h-[500px] overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <ImportCSV handleCSVModalClose={handleCSVModalClose} />
          </div>
        </div>
      )}
      {showDeleteModal && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleDeleteModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-[350px] h-[250px] flex flex-col justify-center text-center items-center overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <p className="text-[20px] font-bold pb-2">
              Are you sure you want to delete?
            </p>
            <div className="flex gap-4 mt-6 justify-center items-center">
              <button
                onClick={handleDeleteModalClose}
                className="border-danger border text-danger hover:bg-danger hover:text-white duration-500 w-[100px] h-[40px] rounded"
              >
                Close
              </button>
              <button
                type="submit"
                className="bg-danger w-[100px] h-[40px] rounded flex items-center justify-center"
                onClick={async () => {
                  if (selectedContacts.length) {
                    await deleteContactMutate({
                      uuid: selectedContacts.join(","),
                    });
                  }
                  handleDeleteModalClose();
                  mutate({
                    page: 1,
                    limit: 100,
                    search,
                  });
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contacts;
