import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "components/Input";
import useCreateContact from "hooks/useCreateContact";
import { Spinner } from "components";
import { Plus, Trash } from "assets/images";

function AddNewContact({ handleContactModalClose, mutate }) {
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
  });

  const { mutateAsync: createContactMutate, isPending: createContactLoading } =
    useCreateContact();
  const [additionalFields, setAdditionalFields] = useState([]);
  const [additionalDetails, setAdditionalDetails] = useState([]);

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    let phoneNumber = value;

    if (phoneNumber.length > 17) {
      phoneNumber = phoneNumber.slice(0, 17);
    }

    setValue("phone", phoneNumber);
    return phoneNumber;
  };

  const handleAddNewFields = (e) => {
    e.preventDefault();
    setAdditionalFields([
      ...additionalFields,
      {
        title: `title_${additionalFields.length}`,
        content: `content_${additionalFields.length}`,
      },
    ]);
  };

  const handleDeleteField = (index) => {
    setAdditionalFields(additionalFields.filter((_, i) => i !== index));
    setAdditionalDetails(additionalDetails.filter((_, i) => i !== index));
  };
  return (
    <div>
      <h2 className="text-[20px] font-bold text-center mb-6">
        Create New Contact
      </h2>
      <p className="text-[18px] font-semibold">Contact Details</p>
      <form className="flex flex-col gap-4 my-8">
        <div>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label="First Name"
                maxLength={50}
                error={errors?.first_name?.message}
                required={true}
                onChange={(event) => {
                  setValue("first_name", event.target.value);
                }}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label="Last Name"
                error={errors?.last_name?.message}
                maxLength={50}
                onChange={(event) => {
                  setValue("last_name", event.target.value);
                }}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label="Phone"
                error={errors?.phone?.message}
                autoComplete="off"
                maxLength={14}
                onChange={(e) => field.onChange(handlePhoneChange(e))}
                required={true}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label="Email Address"
                error={errors?.email?.message}
                maxLength={50}
                onChange={(event) => {
                  setValue("email", event.target.value);
                }}
              />
            )}
          />
        </div>

        <div className="flex flex-col gap-3 w-full text-[16px]">
          <p className="text-[18px] py-3 font-bold">Address</p>
          <div>
            <Controller
              name="street"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label="Street"
                  error={errors?.street?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("street", event.target.value);
                  }}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label="City"
                  error={errors?.city?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("city", event.target.value);
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label="State"
                  error={errors?.state?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("state", event.target.value);
                  }}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="postal_code"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label="Postal Code"
                  error={errors?.postal_code?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("postal_code", event.target.value);
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label="Country"
                  error={errors?.country?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("country", event.target.value);
                  }}
                />
              )}
            />
          </div>
        </div>
        <p className="text-[18px] py-3 font-bold">Work Details</p>
        <div className="flex flex-col gap-3 w-full text-[16px]">
          <div>
            <Controller
              name="company_name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label="Company"
                  placeholder="Enter your company name"
                  error={errors?.company_name?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("company_name", event.target.value);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div>
          <Controller
            name="job_title"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                placeholder="Enter job title"
                label="Job Title"
                error={errors?.job_title?.message}
                maxLength={50}
                onChange={(event) => {
                  setValue("job_title", event.target.value);
                }}
              />
            )}
          />
        </div>
        <div className="flex justify-between w-full items-center">
          <p className="text-[18px] py-3 font-bold">Additional Details</p>
          <button
            onClick={handleAddNewFields}
            className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-black/50 hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
          >
            <Plus className="h-[20px] w-[20px]" />
            Add Field
          </button>
        </div>
        {additionalFields.map((_, index) => (
          <div key={index} className="flex gap-3">
            <div className="w-[40%]">
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Title"
                    maxLength={50}
                    placeholder="Title"
                    onChange={(event) => {
                      const clonedAdditionalDetails = [...additionalDetails];
                      clonedAdditionalDetails[index] = {
                        ...clonedAdditionalDetails[index],
                        key: event.target.value.trim(),
                      };
                      setAdditionalDetails(clonedAdditionalDetails);
                    }}
                  />
                )}
              />
            </div>
            <div className="w-[60%] relative">
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Content"
                    maxLength={50}
                    placeholder="Content"
                    onChange={(event) => {
                      const clonedAdditionalDetails = [...additionalDetails];
                      clonedAdditionalDetails[index] = {
                        ...clonedAdditionalDetails[index],
                        value: event.target.value.trim(),
                      };
                      setAdditionalDetails(clonedAdditionalDetails);
                    }}
                  />
                )}
              />
              <div
                onClick={() => handleDeleteField(index)}
                className="absolute top-[3px] right-0 cursor-pointer"
              >
                <Trash className="w-[20px] h-[20px]" />
              </div>
            </div>
          </div>
        ))}
        <div className="flex gap-4 ml-auto mt-6 justify-end items-center">
          <button
            onClick={handleContactModalClose}
            className="border-danger border text-danger hover:bg-danger hover:text-white duration-500 w-[100px] h-[40px] rounded"
          >
            Close
          </button>
          <button
            type="submit"
            className="bg-green-400 w-[100px] h-[40px] rounded flex items-center justify-center"
            onClick={(event) => {
              event.preventDefault();
              const submitForm = async (values) => {
                await createContactMutate({
                  ...values,
                  additional_details: JSON.stringify(additionalDetails),
                });
                handleContactModalClose();
                mutate();
              };
              handleSubmit(submitForm)();
            }}
          >
            {createContactLoading ? <Spinner /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddNewContact;
