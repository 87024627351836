import { Refresh, Chevron } from 'assets/images';
import React, { useRef, useState } from 'react';
import {
  Avatar,
  Button,
  Dropdown,
  EllipsisTextWithTooltip,
  Skeletons,
  Spinner,
  SubHeader,
} from 'components';
import { useChart } from 'hooks';
import ListManager from 'components/ListManager';
import { getMemberListing } from 'api';
import {
  OWNER,
  formatPhoneNumber,
  roleIconLookup,
  rolesArray,
} from 'shared/resources';
import { useAuth } from 'hooks/useAuth';
import useDashboardStats from 'hooks/useDashboardStats';
import All from 'pages/Calls/All';
import { handleSipStatus } from 'components/Header';

export const LoadingItem = () => {
  return (
    <div className="flex items-center justify-between border-grey-300 py-3 border-b">
      <div className="flex gap-3 items-center">
        <div className="w-9 h-9 rounded-full">
          <Skeletons type="circle" />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center text-sm font-medium	 text-grey-900">
            <div className="w-24">
              <Skeletons />
            </div>
            &nbsp;&nbsp;
            <div className="text-grey-600 w-8">
              <Skeletons />
            </div>
          </div>
          <div className="text-grey-600">
            <Skeletons />
          </div>
        </div>
      </div>
      <div className="w-12 h-5 text-grey-600">
        <Skeletons />
      </div>
    </div>
  );
};

const MemberListItem = ({ item }) => {
  const { user, onlineUsers } = useAuth();
  return (
    <div className="flex items-center justify-between border-grey-300 py-3 border-b">
      <div className="flex gap-3">
        <div className="relative">
          <Avatar
            name={`${item?.first_name} ${item?.last_name ?? ''}`}
            mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
              user?.company_uuid || user?.uuid
            }/profile/${item?.profile_pic}`}
          />
          <div className="absolute left-7 bottom-[1px]">
            {user?.email === item?.email
              ? handleSipStatus(
                  user?.default_status === 'online'
                    ? 'registered'
                    : user?.default_status,
                )
              : item?.default_status === 'online'
              ? handleSipStatus(
                  onlineUsers.includes(item?.email) ? 'registered' : '',
                )
              : handleSipStatus()}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center text-sm font-medium	 text-grey-900 ">
            <div className="flex items-center gap-2">
              <EllipsisTextWithTooltip
                string={`${item?.first_name} ${item?.last_name ?? ''}`}
                charLength={25}
                position="b"
              />

              <span>{item?.uuid === user?.uuid ? '(You)' : ''}</span>
            </div>
          </div>
          <div className="text-xs text-grey-600">
            {formatPhoneNumber(item?.phone)}
          </div>
        </div>
      </div>
      <div
        className={`flex text-xs ${
          item?.role_id === OWNER ? 'text-grey-600' : 'text-grey-600'
        }  gap-1`}
      >
        {roleIconLookup[item?.role_id] || null}
        {rolesArray?.find((role) => role.value === item?.role_id)?.label}
      </div>
    </div>
  );
};

export default function Dashboard() {
  const { getChart } = useChart();
  const [ismemberLoading, setIsmemberLoading] = useState(false);
  const refreshRef = useRef(null);
  const [statsType, setStatsType] = useState('weekly');
  const { isLoading, isFetching, data, refetch } = useDashboardStats({
    statsType,
  });

  function makeMemberLoading() {
    setIsmemberLoading(true);
    setTimeout(() => {
      setIsmemberLoading(false);
    }, 3000);
  }

  const graphData = data?.data?.data?.graph ?? [];

  return (
    <div className="bg-grey-100 body-height overflow-auto">
      <div className="">
        <SubHeader
          isLoading={isLoading}
          data={data}
          statsType={statsType}
          setStatsType={setStatsType}
        />
      </div>
      <div className="xxl:px-24 xl:px-24 lg:px-[88px] md:px-12 sm:px-3 xs:px-3  pt-5 pb-8 ">
        <div>
          <div className="flex gap-7 xxl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col ">
            <div className="xxl:w-9/12 xl:w-[68%] lg:w-[68%] md:w-[65%]">
              <div className="flex items-center justify-between  xs:gap-1 sm:gap-6 mb-2">
                <div className="xs:text-md sm:text-xl  font-medium	 text-grey-900 flex gap-2 items-center">
                  Call summary {}
                </div>
                <div className="sm:w-32 xs:w-28">
                  <Button
                    rounded={true}
                    height="h-10"
                    extraClasses="xs:px-3  sm:px-5 max-w-[120px]"
                    background="grey-200 "
                    border="none"
                    onClick={refetch}
                  >
                    <div className="text-black flex xs:text-sm  items-center gap-2">
                      {/* {isLoading || isFetching ? (
                        <Spinner />
                      ) : ( */}
                      <span className="flex items-center gap-2">
                        {isLoading || isFetching ? (
                          <Spinner />
                        ) : (
                          <>
                            Refresh <Refresh />
                          </>
                        )}
                      </span>
                      {/* )} */}
                    </div>
                  </Button>
                </div>
              </div>

              <div className="border-grey-400 border  rounded-lg  py-4 px-5 bg-white min-h-400 xxl:max-h-[450px] xxl:min-h-[450px] xl:max-h-[350px] xl:min-h-[350px] lg:max-h-[350px] lg:min-h-[350px] md:max-h-[350px] md:min-h-[350px]">
                <div className="flex items-center justify-between xxl:mb-8 xl:mb-8	lg:mb-8 md:mb-2 sm:mb-2 xs:mb-2  gap-4 relative">
                  <div className="flex items-center gap-5 ">
                    <div className="flex items-center gap-2">
                      <div className=" w-3 h-3 rounded-full bg-green-300"></div>
                      Incoming
                    </div>
                    <div className="flex items-center gap-2">
                      <div className=" w-3 h-3 rounded-full bg-green"></div>
                      Outgoing
                    </div>
                    <div className="flex items-center gap-2">
                      <div className=" w-3 h-3 rounded-full bg-danger"></div>
                      Missed
                    </div>
                  </div>

                  <div className="relative">
                    <div className="sm:relative xs:absolute xs:-top-20 xs:right-24 md:top-0 md:right-0">
                      <Dropdown
                        dropList={{
                          component: ({ item }) => (
                            <span
                              key={item?.id}
                              className="w--full d--flex  justify-content--start py-2 px-3 "
                              onClick={() => setStatsType(item?.value)}
                            >
                              {item?.title}
                            </span>
                          ),
                          data: [
                            { title: 'Weekly', value: 'weekly', id: 1 },
                            { title: 'Monthly', value: 'monthly', id: 2 },
                          ],
                        }}
                        closeOnClickOutside={true}
                      >
                        <div className="flex text-sm	 items-center gap-5 font-medium	 bg-grey-300 cursor-pointer text-grey-900 px-4 py-2 rounded-full  capitalize md:relative  xs:mt-1">
                          {statsType}
                          <Chevron />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                {isLoading ? (
                  <Spinner />
                ) : statsType === 'weekly' ? (
                  getChart('ColumnChart', [
                    ['Day', 'Outgoing', 'Incoming', 'Missed'],
                    [
                      'Monday',
                      graphData?.[0]?.['Outbound'] ?? 0,
                      graphData?.[0]?.['Inbound'] ?? 0,
                      graphData?.[0]?.['Missed'] ?? 0,
                    ],
                    [
                      'Tuesday',
                      graphData?.[1]?.['Outbound'] ?? 0,
                      graphData?.[1]?.['Inbound'] ?? 0,
                      graphData?.[1]?.['Missed'] ?? 0,
                    ],
                    [
                      'Wednesday',
                      graphData?.[2]?.['Outbound'] ?? 0,
                      graphData?.[2]?.['Inbound'] ?? 0,
                      graphData?.[2]?.['Missed'] ?? 0,
                    ],
                    [
                      'Thursday',
                      graphData?.[3]?.['Outbound'] ?? 0,
                      graphData?.[3]?.['Inbound'] ?? 0,
                      graphData?.[3]?.['Missed'] ?? 0,
                    ],
                    [
                      'Friday',
                      graphData?.[4]?.['Outbound'] ?? 0,
                      graphData?.[4]?.['Inbound'] ?? 0,
                      graphData?.[4]?.['Missed'] ?? 0,
                    ],
                    [
                      'Saturday',
                      graphData?.[5]?.['Outbound'] ?? 0,
                      graphData?.[5]?.['Inbound'] ?? 0,
                      graphData?.[5]?.['Missed'] ?? 0,
                    ],
                    [
                      'Sunday',
                      graphData?.[6]?.['Outbound'] ?? 0,
                      graphData?.[6]?.['Inbound'] ?? 0,
                      graphData?.[6]?.['Missed'] ?? 0,
                    ],
                  ])
                ) : (
                  getChart('ColumnChart', [
                    ['Day', 'Outgoing', 'Incoming', 'Missed'],
                    [
                      'Jan',
                      graphData?.[0]?.['Outbound'] ?? 0,
                      graphData?.[0]?.['Inbound'] ?? 0,
                      graphData?.[0]?.['Missed'] ?? 0,
                    ],
                    [
                      'Feb',
                      graphData?.[1]?.['Outbound'] ?? 0,
                      graphData?.[1]?.['Inbound'] ?? 0,
                      graphData?.[1]?.['Missed'] ?? 0,
                    ],
                    [
                      'Mar',
                      graphData?.[2]?.['Outbound'] ?? 0,
                      graphData?.[2]?.['Inbound'] ?? 0,
                      graphData?.[2]?.['Missed'] ?? 0,
                    ],
                    [
                      'Apr',
                      graphData?.[3]?.['Outbound'] ?? 0,
                      graphData?.[3]?.['Inbound'] ?? 0,
                      graphData?.[3]?.['Missed'] ?? 0,
                    ],
                    [
                      'May',
                      graphData?.[4]?.['Outbound'] ?? 0,
                      graphData?.[4]?.['Inbound'] ?? 0,
                      graphData?.[4]?.['Missed'] ?? 0,
                    ],
                    [
                      'Jun',
                      graphData?.[5]?.['Outbound'] ?? 0,
                      graphData?.[5]?.['Inbound'] ?? 0,
                      graphData?.[5]?.['Missed'] ?? 0,
                    ],
                    [
                      'Jul',
                      graphData?.[6]?.['Outbound'] ?? 0,
                      graphData?.[6]?.['Inbound'] ?? 0,
                      graphData?.[6]?.['Missed'] ?? 0,
                    ],
                    [
                      'Aug',
                      graphData?.[7]?.['Outbound'] ?? 0,
                      graphData?.[7]?.['Inbound'] ?? 0,
                      graphData?.[7]?.['Missed'] ?? 0,
                    ],
                    [
                      'Sep',
                      graphData?.[8]?.['Outbound'] ?? 0,
                      graphData?.[8]?.['Inbound'] ?? 0,
                      graphData?.[8]?.['Missed'] ?? 0,
                    ],
                    [
                      'Oct',
                      graphData?.[9]?.['Outbound'] ?? 0,
                      graphData?.[9]?.['Inbound'] ?? 0,
                      graphData?.[9]?.['Missed'] ?? 0,
                    ],
                    [
                      'Nov',
                      graphData?.[10]?.['Outbound'] ?? 0,
                      graphData?.[10]?.['Inbound'] ?? 0,
                      graphData?.[10]?.['Missed'] ?? 0,
                    ],
                    [
                      'Dec',
                      graphData?.[11]?.['Outbound'] ?? 0,
                      graphData?.[11]?.['Inbound'] ?? 0,
                      graphData?.[11]?.['Missed'] ?? 0,
                    ],
                  ])
                )}
              </div>
            </div>
            <div className="xxl:w-3/12 xl:w-[30%] lg:w-[30%] md:w-[35%]">
              <div className="flex items-center xs:justify-between md:justify-start gap-6 mb-2">
                <div className="xs:text-md sm:text-xl  font-medium	 text-grey-900">
                  Members
                </div>
                <div className="sm:w-32 xs:w-28">
                  <Button
                    rounded={true}
                    height="h-10"
                    extraClasses=" xs:px-3  sm:px-5 max-w-[120px]"
                    background="grey-200 "
                    border="none"
                    onClick={() => {
                      refreshRef?.current?.refresh();
                      makeMemberLoading();
                    }}
                  >
                    <div className="text-black flex xs:text-sm  items-center gap-2">
                      {ismemberLoading ? (
                        <Spinner />
                      ) : (
                        <>
                          Refresh <Refresh />
                        </>
                      )}
                    </div>
                  </Button>
                </div>
              </div>
              <div className="border-grey-400 border  rounded-lg  px-6 py-3 bg-white min-h-400 xxl:max-h-[450px] xxl:min-h-[450px] xl:max-h-[350px] xl:min-h-[350px] lg:max-h-[350px] lg:min-h-[350px] md:max-h-[350px] md:min-h-[350px] overflow-auto">
                <ListManager
                  ref={refreshRef}
                  actionFn={getMemberListing}
                  queryKey="getMemberListing"
                  listItem={MemberListItem}
                  loadingComponent={LoadingItem}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="xs:text-md sm:text-xl font-medium	 text-grey-900 mb-4">
              Recent call history
            </div>
            <All />
          </div>
        </div>
      </div>
    </div>
  );
}
