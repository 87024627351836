import React, { useEffect, useState } from "react";
import PricingItem from "./PricingItem";
import { getPlans } from "api";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "hooks/useAuth";
import { Button, Modal, Skeletons, Spinner } from "components";
import { dropdownActions, initialModalState } from "../constants";
import { ChangePlanModal } from "./ChangePlanModal";
import { ViewPlanModal } from "./ViewPlanModal";
import BuyPlanModal from "./BuyPlanModal";
import useGetRequestedPlan from "hooks/useGetRequestedPlan";
import { formatDateTime } from "shared/resources";
import useCancelRequestPlan from "hooks/useCancelRequestPlan";

const numberOfPlans = 3;

const optionsItems = [
  {
    key: "cost",
    value: "Cost",
    type: "value",
  },
  {
    key: "call_local",
    value: "Call Local",
    type: "value",
  },
  {
    key: "messaging_local",
    value: "Messaging Local",
    type: "value",
  },
  {
    key: "review_and_setup_free",
    value: "Review and Setup Free",
    type: "value",
  },
  {
    key: "phone_numbers_per_user",
    value: "Phone Numbers Per User",
    type: "value",
  },
  {
    key: "additional_phone_number",
    value: "Additional Phone Number",
    type: "value",
  },
  {
    key: "free_call_valid_for_one_month",
    value: "Free Call Valid For One Month",
    type: "value",
  },
  {
    key: "offnet_call_rate_discount",
    value: "OffNet Call Rate Discount",
    type: "value",
  },
  {
    key: "call_recording",
    value: "Call Recording",
    type: "value",
  },
  {
    key: "mobile_app_feature_for_customer_service",
    value: "Mobile App Feature For Customer Service",
    type: "value",
  },
  {
    key: "transfer_call_feature",
    value: "Transfer Call Feature",
    type: "value",
  },
  {
    key: "voice_response_ivr",
    value: "Voice Response IVR",
    type: "value",
  },
  {
    key: "business_hour_settings",
    value: "Business Hour Settings",
    type: "value",
  },
];

const PlanLoader = () => (
  <div className={`border border-grey-400 rounded overflow-hidden w-[30%]`}>
    <div className={` h-1`}></div>
    <div className={`h-32 p-3  border-b border-grey-400`}>
      <Skeletons />
    </div>
    <div>
      {Array.from({ length: optionsItems?.length - 1 }, (_, i) => i + 1).map(
        (val) => {
          return (
            <div
              key={val}
              className={`border-b border-grey-400 p-3 
            }`}
            >
              <Skeletons />
            </div>
          );
        }
      )}
    </div>
  </div>
);

const Pnb = () => {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(initialModalState);
  const { data: requestedPlanData } = useGetRequestedPlan();
  const { mutate: cancelRequestedPlan, isPending } = useCancelRequestPlan();
  const myPlan = user?.get_company_details.plan_uuid;
  const [isMonthly, setIsMonthly] = useState(true);
  const {
    isLoading: isLoadingData,
    data,
    isFetching,
  } = useQuery({
    queryFn: ({ queryKey }) => getPlans(queryKey[1] || {}),
    queryKey: [`getPlans`],
    select: (data) => data?.data?.data,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    gcTime: Infinity,
  });

  useEffect(() => {
    if (user?.get_company_details?.plan_duration === "YEARLY") {
      setIsMonthly(false);
    }
  }, [user]);

  function handleChangePlan(planId, action) {
    setShowModal({
      isShow: true,
      modalType: dropdownActions.BUY_PLAN,
      elementData: planId,
      type: "center",
      plan_action: action || undefined,
    });
    // setShowModal({
    //   isShow: true,
    //   modalType: dropdownActions.CHANGE_PLAN,
    //   elementData: planId,
    //   type: 'center',
    // });
  }

  function handleViewPlan(data) {
    setShowModal({
      isShow: true,
      modalType: dropdownActions.VIEW_PLAN,
      type: "center",
      elementData: { ...data },
    });
  }

  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  const ModalLookup = {
    [dropdownActions.CHANGE_PLAN]: (
      <ChangePlanModal showModal={showModal} handleClose={handleCloseModal} />
    ),
    [dropdownActions.VIEW_PLAN]: (
      <ViewPlanModal
        showModal={showModal}
        handleClose={handleCloseModal}
        allPlans={data}
      />
    ),
    [dropdownActions.BUY_PLAN]: (
      <BuyPlanModal
        showModal={showModal}
        handleClose={handleCloseModal}
        isMonthly={isMonthly}
      />
    ),
  };

  const isLoading = isLoadingData || isFetching;

  const { isShow, modalType } = showModal;

  const oldPlan =
    data?.find(
      (plan) => plan?.uuid === requestedPlanData?.data?.data?.old_plan_uuid
    ) ?? {};

  const newPlan =
    data?.find(
      (plan) => plan?.uuid === requestedPlanData?.data?.data?.plan_uuid
    ) ?? {};

  return (
    <>
      <div className="px-6 py-6">
        {requestedPlanData?.data?.data ? (
          <div className="border px-5 py-4 rounded-md bg-green-50 border-green-100 flex items-center justify-between mb-6">
            <div>
              <div className="font-semibold	text-base text-green">
                Change plan requested
              </div>
              <div className="font-medium mt-1 text-base text-green">
                You have requested to change your plan from{" "}
                <b>{oldPlan?.plan_name}</b> to <b>{newPlan?.plan_name}</b> on{" "}
                {formatDateTime(requestedPlanData?.data?.data?.created_at)?.[0]}
              </div>
            </div>
            <div className="w-40">
              <Button
                height="h-[50px]"
                extraClasses=" px-5"
                onClick={() =>
                  cancelRequestedPlan({
                    uuid: requestedPlanData?.data?.data?.uuid,
                  })
                }
              >
                {isPending ? <Spinner /> : " Cancel Request"}
              </Button>
            </div>
          </div>
        ) : null}
        <div className="flex items-center justify-between mb-5">
          <div className="text-sm text-grey-700">Manage plan & billing</div>
          <div className="rounded-full border-green-400 border-2 p-4 px-6 flex gap-5">
            <button
              onClick={() => {
                setIsMonthly(true);
              }}
              className={
                isMonthly
                  ? "bg-green-400 p-2 rounded-full"
                  : "bg-none text-[12px]"
              }
            >
              Monthly
            </button>
            <button
              onClick={() => {
                setIsMonthly(false);
              }}
              className={
                !isMonthly
                  ? "bg-green-400 p-2 rounded-full"
                  : "bg-none text-[12px]"
              }
            >
              Yearly
            </button>
          </div>
        </div>
        <div className="flex gap-7">
          <PricingItem
            title={"Options"}
            itemsArr={optionsItems}
            width="w-2/5"
            headerBg="bg-green-100"
            withActionBtn={false}
            textCenter={false}
            isLoading={isLoading}
            data={data}
            isMonthly={isMonthly}
          />

          {isLoading ? (
            <>
              {Array.from({ length: numberOfPlans }, (_, i) => i + 1).map(
                (val) => {
                  return <PlanLoader key={val} />;
                }
              )}
            </>
          ) : data && data.length > 0 ? (
            <>
              {data?.map((plan) => {
                const isCurrentPlan = plan?.uuid === myPlan;
                return (
                  <PricingItem
                    isActivePlan={isCurrentPlan}
                    title={plan?.plan_name}
                    key={plan?.uuid}
                    itemsArr={plan?.features}
                    isLoading={isLoading}
                    data={data}
                    highiLight={isCurrentPlan ? "bg-green" : "bg-black-600"}
                    bg={isCurrentPlan ? "bg-green" : "bg-white"}
                    headerBg={isCurrentPlan ? "bg-green" : "bg-white"}
                    planId={plan?.uuid}
                    handleChangePlan={handleChangePlan}
                    handleViewPlan={handleViewPlan}
                    action={plan?.action}
                    isMonthly={isMonthly}
                  />
                );
              })}
            </>
          ) : null}
        </div>
      </div>

      {isShow ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </>
  );
};

export default Pnb;
