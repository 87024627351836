import { Download } from "assets/images";
import React, { useState } from "react";
import { Spinner } from "components";
import axios from "axios";
import { getToken } from "shared/resources";
import { toastEmitter } from "components/Toast";
import { routes } from "api/routes";

const ImportCSV = ({ handleCSVModalClose }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
      setError("");
    } else {
      setFile(null);
      setError("Please upload a valid CSV file.");
    }
  };

  return (
    <div>
      <h1 className="text-[20px] font-bold my-6">Upload a CSV File</h1>

      <a
        className="text-[#4F772D] text-[18px] pb-6 flex gap-2 font-bold"
        href="/sample.csv"
        download
      >
        <Download />
        Download a sample CSV file
      </a>

      <form>
        <div
          className={`w-full text-sm border border-black/30 rounded-lg cursor-pointer h-[200px] justify-center items-center flex flex-col bg-black/20 focus:outline-none mb-2`}
        >
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            className="cursor-pointer focus:outline-none"
          />
        </div>
        {error && <p className="text-danger text-sm mb-6">{error}</p>}

        <div className="flex gap-4 mt-10 ml-auto justify-end items-center">
          <button
            onClick={handleCSVModalClose}
            className="border-danger border text-danger hover:bg-danger hover:text-white duration-500 w-[100px] h-[40px] rounded"
          >
            Close
          </button>
          <button
            type="submit"
            className="bg-green-400 w-[100px] h-[40px] rounded flex items-center justify-center"
            onClick={async (event) => {
              event.preventDefault();
              setLoading(true);
              if (file) {
                const formData = new FormData();
                formData.append("file", file);
                try {
                  const response = await axios.post(
                    `${process.env.REACT_APP_BASE_API_URL}${routes.IMPORT_CONTACT.URL}`,
                    formData,
                    {
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${getToken()}`,
                      },
                    }
                  );
                  toastEmitter("success", response?.data?.data?.message);
                } catch (error) {
                  if (error?.response?.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    return (window.location.href = "/");
                  } else {
                    toastEmitter(
                      "error",
                      error?.response?.data?.error?.message
                    );
                  }
                }
                handleCSVModalClose();
              } else {
                setError("Please upload a CSV file before submitting.");
              }
              setLoading(false);
            }}
          >
            {loading ? <Spinner /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ImportCSV;
