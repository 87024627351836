import React, { useContext, useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Card,
  Chevron,
  DotsNine,
  NotificationBell,
  Prohibit,
  SignOut,
  UserCircle,
  X,
} from "assets/images";
import { Logo } from "assets/images/signup";
import {
  ADMIN,
  MEMBER,
  NG_CURRENCY_SYMBOL,
  OWNER,
  getToken,
  navigationArr,
} from "shared/resources";
import Dropdown from "components/Dropdown";
import Modal from "components/Modal";
import WelcomeFonuModal from "pages/Dashboard/WelcomeFonuModal";
import { PhoneSystem } from "layouts";
import Avatar from "components/Avatar";
import { useAuth } from "hooks/useAuth";
import { SipSocketContext } from "hooks/useSip/SipSocketContext";
import Tooltip from "components/Tooltip";
import { useWalletDetails } from "hooks";
import axios from "axios";
import { initialModalState } from "pages/Dashboard/constants";
import { Button, Skeletons, Spinner } from "components";
import PaymentConfirmationModal from "components/PaymentConfirmationModal";
import { useForm, Controller } from "react-hook-form";
import useChangePlan from "hooks/useChangePlan";
import useMakePayment from "hooks/useMakePayment";
import useSavedCards from "hooks/useSavedCards";
import {
  CardItem,
  PaymentMethodArray,
} from "pages/PhoneNumbers/PurchaseNumberModal/NumberDetail";

function handleSipStatus(_status) {
  if (_status === "registered") {
    return (
      <Tooltip title="Connected" position="b">
        <span className="inline-flex h-3 w-3 rounded-full bg-green-400"></span>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Disconnected" position="b">
      <span className="inline-flex h-3 w-3 rounded-full bg-grey-500"></span>
    </Tooltip>
  );
}

function DropdownList({ item }) {
  const Icon = item?.icon;
  return (
    <div
      className="w-full flex  items-center gap-3 py-2 px-3 whitespace-nowrap"
      onClick={item?.onclick}
    >
      {Icon} {item?.title}
    </div>
  );
}

export default function Header() {
  const { user, clearUser } = useAuth();
  const userRole = user?.role_id;
  const [isDialpadOpen, openDialpad] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [sidebarList, setSidebarList] = useState([]);
  const { data: walletDetails } = useWalletDetails();
  const { socket = {} } = useContext(SipSocketContext);
  const searchParam = new URLSearchParams(window?.location?.search);
  const navigate = useNavigate();
  const showWelcomeModal = searchParam?.get("current") === "from-base-origin";
  const [expiredSubs, setExpiredSubs] = useState([]);
  const [showModal, setShowModal] = useState(initialModalState);
  const [selectedSub, setSelectedSub] = useState(null);
  const { cardListing, isLoading } = useSavedCards();

  const { control, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues: {
      payment_type: "NEW_CARD",
      is_saved_card: "N",
      card_uuid: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (
      cardListing &&
      cardListing?.length > 0 &&
      watch("payment_type") === "SAVED_CARD"
    ) {
      const defaultCard = cardListing?.find((item) => item?.is_default === "Y");
      setValue("card_uuid", defaultCard?.uuid || "");
    }
  }, [cardListing?.length, watch("payment_type")]);

  useEffect(() => {
    fetchExpiredSubscriptions();
  }, []);

  useEffect(() => {
    const open = searchParam.get("open");
    const selectedSub = searchParam.get("selectedSub");
    if (open && selectedSub) {
      setSelectedSub(JSON.parse(selectedSub));
      setShowModal({
        modalType: open,
        isShow: true,
        elementData: {},
        type: "center",
      });
    }
  }, [searchParam]);

  const fetchExpiredSubscriptions = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}subscriptions/expired-subscriptions`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    setExpiredSubs(response.data.data);
  };

  function handleCloseWelcomeModal() {
    if (window.location.pathname === "/") {
      navigate("/overview");
    } else {
      navigate(window.location.pathname);
    }
  }

  useEffect(() => {
    const result = navigationArr?.filter(({ access }) =>
      access?.includes(userRole)
    );
    setSidebarList(result);
  }, [userRole]);

  const { isPending: isPlanPending, mutate: changePlanMutate } = useChangePlan({
    handleSuccess: () => {
      window.location.reload();
    },
  });

  const { isPending: isDidPending, mutate: didCheckoutMutate } = useMakePayment(
    {
      handleSuccess: () => {
        window.location.reload();
      },
    }
  );

  const ModalLookup = useMemo(() => {
    let price = 0;
    if (selectedSub && selectedSub?.features) {
      const features = JSON.parse(selectedSub?.features);
      price =
        selectedSub?.duration === "MONTHLY"
          ? Number(features[0]?.value).toFixed(2)
          : Number(features[0]?.value * 12).toFixed(2);
    }
    return {
      PLAN_RENEWAL: (
        <div className="">
          <div className="flex justify-between items-start pt-4 px-4">
            <div className="flex flex-col gap-1">
              <div className="text-4xl font-semibold text-black">
                Fonu {selectedSub?.plan_name} Plan Renewal
              </div>
              <div className="text-sm  text-grey-700">
                You&apos;re about to renew your Fonu {selectedSub?.plan_name}.
                Click on the payment button below to pay.
              </div>
            </div>
            <button
              type="button"
              onClick={() => setShowModal(initialModalState)}
            >
              <X className="text-grey-600 w-6 h-6" />
            </button>
          </div>
          <div
            style={{ margin: "20px 0" }}
            className="  px-4 pb-6 w-full xxl:max-h-full xl:max-h-full lg:max-h-full  md:overflow-auto md:max-h-56"
          >
            <div className="text-4xl font-semibold">
              {NG_CURRENCY_SYMBOL}
              &nbsp;
              {price}
              <span className="text-2xl text-grey-700 capitalize">
                {user?.get_company_details?.plan_duration}
              </span>
            </div>
          </div>
          <div className="flex w-full gap-5 mt-8 px-4">
            {PaymentMethodArray.map((item) => {
              return (
                <label
                  htmlFor={`payment_type_${item?.type}`}
                  key={item?.type}
                  className="flex items-center gap-1 font-medium text-grey-800 cursor-pointer"
                >
                  <Controller
                    name={"payment_type"}
                    control={control}
                    render={({ field }) => {
                      return (
                        <input
                          className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                          type="radio"
                          {...field}
                          id={`payment_type_${item?.type}`}
                          value={item?.type}
                          checked={item?.type === watch("payment_type")}
                        />
                      );
                    }}
                  />
                  {item?.label}
                </label>
              );
            })}
          </div>
          {watch("payment_type") === "SAVED_CARD" ? (
            <div className="w-full px-4 mt-4 mb-3 gap-2 flex items-center max-w-[600px] overflow-auto flex-col max-h-28">
              {isLoading
                ? Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
                    return (
                      <div className="w-full" key={val}>
                        <Skeletons height="h-14" />
                      </div>
                    );
                  })
                : cardListing?.length > 0
                ? cardListing?.map((card) => (
                    <CardItem
                      key={card?.uuid}
                      data={card}
                      setSelectedCard={(val) => setValue("card_uuid", val)}
                      selectedCard={watch("card_uuid")}
                    />
                  ))
                : "No saved cards"}
            </div>
          ) : null}
          {watch("payment_type") === "NEW_CARD" ? (
            <div className="w-full px-4 mt-4 mb-10 gap-2 flex items-center">
              <input
                className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                type="checkbox"
                id="is_saved_card"
                checked={watch("is_saved_card") === "Y"}
                onChange={(e) => {
                  setValue("is_saved_card", e.target.checked ? "Y" : "N");
                }}
              />
              <label className="cursor-pointer" htmlFor="is_saved_card">
                Do you want to save this card for future use
              </label>
            </div>
          ) : null}
          <div className="mt-4  flex items-center justify-end gap-2 px-4 pb-4">
            <Button
              width="w-[191px]"
              type="button"
              onClick={() => {
                if (watch("payment_type") === "NEW_CARD") {
                  setShowModal({
                    modalType: "PLAN_PURCHASE_CONFIRMATION",
                    isShow: true,
                    elementData: {},
                    type: "center",
                  });
                } else {
                  const values = getValues();
                  changePlanMutate({
                    ...values,
                    plan_uuid: selectedSub?.uuid,
                    payment_type: values?.payment_type,
                    is_saved_card: values?.is_saved_card,
                    plan_action: showModal?.plan_action ?? undefined,
                    plan_type: selectedSub?.duration,
                    source: values?.source,
                  });
                }
              }}
            >
              <div className="flex w-fit px-5   justify-center items-center">
                {isPlanPending ? <Spinner /> : "Pay now"}
              </div>
            </Button>
          </div>
        </div>
      ),
      PLAN_PURCHASE_CONFIRMATION: (
        <PaymentConfirmationModal
          handleClose={() => {
            setShowModal({
              isShow: true,
              modalType: "PLAN_RENEWAL",
              type: "center",
            });
          }}
          continueCallBack={handleSubmit((values) => {
            changePlanMutate({
              plan_uuid: selectedSub?.uuid,
              payment_type: values?.payment_type,
              is_saved_card: values?.is_saved_card,
              plan_action: showModal?.plan_action ?? undefined,
              plan_type: selectedSub?.duration,
              source: values?.source,
            });
          })}
          setValue={setValue}
        />
      ),
      DID_PURCHASE_CONFIRMATION: (
        <PaymentConfirmationModal
          handleClose={() => {
            setShowModal({
              isShow: true,
              modalType: "DID_RENEWAL",
              type: "center",
            });
          }}
          continueCallBack={handleSubmit((values) => {
            didCheckoutMutate({
              did_uuids: selectedSub?.dids.reduce((prev, cur) => {
                prev.push(cur.uuid);
                return prev;
              }, []),
              payment_type: values?.payment_type, // NEW_CARD, SAVED_CARD, WALLET
              is_saved_card: values?.is_saved_card, // Y, N
              card_uuid: values?.card_uuid, // required if payment_type is SAVED_CARD
              plan_type: selectedSub?.dids[0]?.buy_duration, // MONTHLY, ANNUALLY OR (empty)
              type: "", // APP or (empty)
              source: values?.source,
            });
          })}
          setValue={setValue}
        />
      ),
      DID_RENEWAL: (
        <div className="">
          <div className="flex justify-between items-start pt-4 px-4">
            <div className="flex flex-col gap-1">
              <div className="text-4xl font-semibold text-black">
                Fonu DID Renewal
              </div>
              <div className="text-sm text-grey-700">
                You&apos;re about to renew your Fonu DIDs:{" "}
                {selectedSub?.dids
                  ?.reduce((prev, cur) => {
                    prev.push(cur.did_number);
                    return prev;
                  }, [])
                  .join(", ")}
                . Click on the payment button below to pay.
              </div>
            </div>
            <button
              type="button"
              onClick={() => setShowModal(initialModalState)}
            >
              <X className="text-grey-600 w-6 h-6" />
            </button>
          </div>
          <div
            style={{ margin: "20px 0" }}
            className="px-4 pb-6 w-full xxl:max-h-full xl:max-h-full lg:max-h-full  md:overflow-auto md:max-h-56"
          >
            <div className="text-4xl font-semibold">
              {NG_CURRENCY_SYMBOL}
              &nbsp;
              {Number(
                selectedSub?.dids?.reduce((prev, cur) => {
                  prev +=
                    cur.buy_duration === "MONTHLY"
                      ? cur.monthly_cost
                      : cur.monthly_cost * 12;
                  return prev;
                }, 0)
              ).toFixed(2)}
              &nbsp;&nbsp;
              <span className="text-2xl text-grey-700 capitalize">
                {selectedSub?.dids && selectedSub?.dids[0]?.buy_duration}
              </span>
            </div>
          </div>
          <div className="flex w-full gap-5 mt-8 px-4">
            {PaymentMethodArray.map((item) => {
              return (
                <label
                  htmlFor={`payment_type_${item?.type}`}
                  key={item?.type}
                  className="flex items-center gap-1 font-medium text-grey-800 cursor-pointer"
                >
                  <Controller
                    name={"payment_type"}
                    control={control}
                    render={({ field }) => {
                      return (
                        <input
                          className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                          type="radio"
                          {...field}
                          id={`payment_type_${item?.type}`}
                          value={item?.type}
                          checked={item?.type === watch("payment_type")}
                        />
                      );
                    }}
                  />
                  {item?.label}
                </label>
              );
            })}
          </div>
          {watch("payment_type") === "SAVED_CARD" ? (
            <div className="w-full px-4 mt-4 mb-3 gap-2 flex items-center max-w-[600px] overflow-auto flex-col max-h-28">
              {isLoading
                ? Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
                    return (
                      <div className="w-full" key={val}>
                        <Skeletons height="h-14" />
                      </div>
                    );
                  })
                : cardListing?.length > 0
                ? cardListing?.map((card) => (
                    <CardItem
                      key={card?.uuid}
                      data={card}
                      setSelectedCard={(val) => setValue("card_uuid", val)}
                      selectedCard={watch("card_uuid")}
                    />
                  ))
                : "No saved cards"}
            </div>
          ) : null}
          {watch("payment_type") === "NEW_CARD" ? (
            <div className="w-full px-4 mt-4 mb-10 gap-2 flex items-center">
              <input
                className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                type="checkbox"
                id="is_saved_card"
                checked={watch("is_saved_card") === "Y"}
                onChange={(e) => {
                  setValue("is_saved_card", e.target.checked ? "Y" : "N");
                }}
              />
              <label className="cursor-pointer" htmlFor="is_saved_card">
                Do you want to save this card for future use
              </label>
            </div>
          ) : null}
          <div className="mt-4  flex items-center justify-end gap-2 px-4 pb-4">
            <Button
              width="w-[191px]"
              type="button"
              onClick={() => {
                if (watch("payment_type") === "NEW_CARD") {
                  setShowModal({
                    modalType: "DID_PURCHASE_CONFIRMATION",
                    isShow: true,
                    elementData: {},
                    type: "center",
                  });
                } else {
                  const values = getValues();
                  didCheckoutMutate({
                    did_uuids: selectedSub?.dids.reduce((prev, cur) => {
                      prev.push(cur.uuid);
                      return prev;
                    }, []),
                    payment_type: values?.payment_type, // NEW_CARD, SAVED_CARD, WALLET
                    is_saved_card: values?.is_saved_card, // Y, N
                    card_uuid: values?.card_uuid, // required if payment_type is SAVED_CARD
                    plan_type: selectedSub?.dids[0]?.buy_duration, // MONTHLY, ANNUALLY OR (empty)
                    type: "", // APP or (empty)
                    source: values?.source,
                  });
                }
              }}
            >
              <div className="flex w-fit px-5   justify-center items-center">
                {isDidPending ? <Spinner /> : "Pay now"}
              </div>
            </Button>
          </div>
        </div>
      ),
    };
  }, [
    selectedSub,
    watch("is_saved_card"),
    watch("payment_type"),
    watch("card_uuid"),
    isDidPending,
    isPlanPending,
  ]);

  const { _status = "" } = socket;
  const { isShow, modalType, type } = showModal;

  return (
    <>
      {expiredSubs.map((sub) => {
        if (sub.status && sub.status.includes("plan")) {
          return sub.status.includes("about") ? (
            <div className="bg-[#eed202] py-5 flex h-4 items-center justify-center border-top-grey xxl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-3 xs:px-3">
              Hi, {user?.first_name ?? ""} you have{" "}
              {user?.get_company_details?.remaining_days + 1}&nbsp; day(s) left
              before your plan expires.&nbsp;&nbsp;
              <span
                className="cursor-pointer underline italic"
                onClick={() => {
                  setShowModal({
                    modalType: "PLAN_RENEWAL",
                    isShow: true,
                    type: "center",
                  });
                  setSelectedSub(sub);
                }}
              >
                <b>Pay Now</b>
              </span>
            </div>
          ) : (
            <div className="bg-[#eed202] py-5 flex h-4 items-center justify-center border-top-grey xxl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-3 xs:px-3">
              Hi, {user?.first_name ?? ""} your plan has expired.&nbsp;&nbsp;
              <span
                className="cursor-pointer underline italic"
                onClick={() => {
                  setShowModal({
                    isShow: true,
                    modalType: "PLAN_RENEWAL",
                    type: "center",
                  });
                  setSelectedSub(sub);
                }}
              >
                <b>Pay Now</b>
              </span>
            </div>
          );
        } else {
          return (
            sub.dids && (
              <div className="bg-[#eed202] py-5 flex h-4 items-center justify-center border-top-grey xxl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-3 xs:px-3">
                Hi {user?.first_name ?? ""}, the following DIDs:{" "}
                {sub.dids
                  .reduce((prev, cur) => {
                    prev.push(cur.did_number);
                    return prev;
                  }, [])
                  .join(", ")}{" "}
                are due for renewal.&nbsp;&nbsp;
                <span
                  className="cursor-pointer underline italic"
                  onClick={() => {
                    setShowModal({
                      modalType: "DID_RENEWAL",
                      isShow: true,
                      type: "center",
                    });
                    setSelectedSub(sub);
                  }}
                >
                  <b>Pay Now</b>
                </span>
              </div>
            )
          );
        }
      })}

      {isShow && type === "center" ? (
        <Modal
          handleClose={() => {
            setShowModal(initialModalState);
          }}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}

      <div className="bg-black-600  py-5 flex items-center justify-between border-top-grey xxl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-3 xs:px-3">
        <div className=" flex text-base items-center xxl:gap-14 xl:gap-10 lg:gap-8 md:gap-6 sm:gap-8 xs:gap-6 xxl:justify-between xl:justify-start lg:justify-start md:justify-start sm:justify-between xs:justify-between   xxl:w-auto xl:w-auto lg:w-auto md:w-auto  sm:w-auto xs:w-[calc(100%_-_30px)]">
          <Logo className="w-20" />

          <div className="absolute top-5 right-0 flex items-center xxl:hidden  xl:hidden lg:hidden  md:hidden sm:block  xs:block ">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-white  "
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>

              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>

              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div
            className={`absolute bg-black-600 top-16 left-0 w-full p-5 flex-col xs:gap-4 sm:gap-6 z-10 ${
              showDropdown ? "sm:flex xs:flex" : " sm:hidden xs:hidden"
            } sm:ml-6`}
          >
            {sidebarList?.map((nav) => {
              const isActive = window.location.href.includes(nav?.parent);
              if (
                ["Teams", "Phone Numbers", "Calls", "Overview"].includes(
                  nav?.label
                )
              ) {
                return (
                  <div
                    key={nav?.id}
                    className="flex  items-center group relative  "
                    onClick={() => setShowDropdown((prev) => !prev)}
                  >
                    <NavLink
                      to={nav?.path}
                      className={
                        isActive
                          ? "text-white xxl:text-base xl:text-base lg:text-sm md:text-xs font-medium cursor-pointer xs:text-green sm:border-none xs:border-b sm:text-white xs:border-green xs:w-full sm:w-auto xs:pb-4 sm:pb-0"
                          : "text-grey-800 cursor-pointer xxl:text-base xl:text-base lg:text-sm md:text-xs group-hover:text-white sm:border-none xs:border-b xs:border-grey-100 xs:w-full sm:w-auto xs:pb-4 sm:pb-0"
                      }
                    >
                      {nav?.label}
                    </NavLink>
                    <div
                      className={`${
                        isActive
                          ? "xs:invisible sm:visible"
                          : "xs:invisible sm:invisible"
                      } group-hover:visible w-full h-0.5 bg-green rounded-sm absolute top-7`}
                    ></div>
                  </div>
                );
              }
            })}
          </div>

          <div className=" items-center gap-4 xxl:hidden  xl:hidden lg:hidden  md:hidden sm:flex  xs:flex">
            <div
              className="xs:hidden sm:flex   items-center gap-1 justify-center xs:h-8 xs:w-8 md:h-10 md:w-10	xs:text-white sm:text-black cursor-pointer sm:bg-white xs:bg-black-600 rounded-full relative"
              onClick={() => openDialpad(true)}
            >
              <DotsNine />
              <div className="absolute top-0 right-0">
                {handleSipStatus(_status)}
              </div>
            </div>

            <NotificationBell className="text-white" />
            <div className=" text-white  ">
              <Dropdown
                caretComponent={() => <></>}
                extraClassName=" bg--black-200"
                closeOnClickOutside={true}
                dropList={{
                  component: ({ item }) => {
                    if (
                      item?.access?.length === 0 ||
                      !item.access.includes(user?.role_id)
                    ) {
                      return;
                    }
                    return <DropdownList key={item?.id} item={item} />;
                  },
                  data: [
                    {
                      title: "Profile",
                      value: "profile",
                      onclick: () => navigate("/profile/general"),
                      icon: <UserCircle className="text-grey-700" />,
                      access: [ADMIN, OWNER, MEMBER],
                      id: 1,
                    },
                    {
                      title: "Plan & billing",
                      value: "planBilling",
                      onclick: () => navigate("/profile/pnb"),
                      icon: <Card className="text-grey-700" />,
                      access: [ADMIN, OWNER],
                      id: 2,
                    },
                    {
                      title: "Blocklist",
                      value: "blocklist",
                      onclick: () => navigate("/profile/blocklist"),
                      icon: <Prohibit className="text-grey-700" />,
                      access: [ADMIN, OWNER],
                      id: 3,
                    },
                    {
                      title: "Logout",
                      value: "logout",
                      onclick: clearUser,
                      icon: <SignOut className="text-grey-700" />,
                      access: [ADMIN, OWNER, MEMBER],
                      id: 4,
                    },
                  ],
                }}
                showcaret={true}
              >
                <div className="flex items-center gap-3 cursor-pointer">
                  <Avatar
                    name={`${user?.first_name} ${user?.last_name}`}
                    mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
                      user?.company_uuid || user?.uuid
                    }/profile/${user?.profile_pic}`}
                  />

                  <div className="flex flex-col gap-1">
                    <div className="flex items-center text-sm font-medium	 text-white">
                      {NG_CURRENCY_SYMBOL}
                      {walletDetails?.accountBalance || 0}
                    </div>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>

          <div className="flex items-center xxl:gap-8 xl:gap-6 lg:gap-6 md:gap-6  xxl:flex  xl:flex lg:flex  md:flex sm:hidden  xs:hidden">
            {sidebarList?.map((nav) => {
              const isActive = window.location.href.includes(nav?.parent);
              return (
                <div
                  key={nav?.id}
                  className="flex justify-center items-center group relative "
                >
                  <NavLink
                    to={nav?.path}
                    className={
                      isActive
                        ? "text-white xxl:text-base xl:text-base lg:text-sm md:text-xs font-medium cursor-pointer"
                        : "text-grey-800 cursor-pointer xxl:text-base xl:text-base lg:text-sm md:text-xs group-hover:text-white "
                    }
                  >
                    {nav?.label}
                  </NavLink>
                  <div
                    className={`${
                      isActive ? "visible" : "invisible"
                    } group-hover:visible w-3 h-0.5 bg-green rounded-sm absolute top-7`}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
        <div className=" items-center xxl:gap-6 xl:gap-4 lg:gap-3 md:gap-2 xxl:flex  xl:flex lg:flex  md:flex sm:hidden  xs:hidden">
          <div
            className="flex  items-center gap-1 justify-center w-auto h-auto 	 text-black cursor-pointer bg-white px-3 py-2  rounded-full"
            onClick={() => openDialpad(true)}
          >
            <DotsNine />
            <div className="xxl:text-base  xl:text-sm  lg:text-base  md:text-xs  flex font-medium  items-center gap-1">
              Dial pad {handleSipStatus(_status)}
            </div>
          </div>

          <NotificationBell className="text-white" />
          <div className="min-w-150 text-white  max-w-150">
            <Dropdown
              caretComponent={Chevron}
              extraClassName="min-w-150  max-w-150  bg--black-200"
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => {
                  if (
                    item?.access?.length === 0 ||
                    !item.access.includes(user?.role_id)
                  ) {
                    return;
                  }
                  return <DropdownList key={item?.id} item={item} />;
                },
                data: [
                  {
                    title: "My account",
                    value: "profile",
                    onclick: () => navigate("/profile/general"),
                    icon: <UserCircle className="text-grey-700 w-5 h-5" />,
                    access: [ADMIN, OWNER, MEMBER],
                    id: 1,
                  },
                  {
                    title: "Plan & billing",
                    value: "planBilling",
                    onclick: () => navigate("/profile/pnb"),
                    icon: <Card className="text-grey-700 w-[18px] h-[18px]" />,
                    access: [ADMIN, OWNER],
                    id: 2,
                  },
                  {
                    title: "Blocklist",
                    value: "blocklist",
                    onclick: () => navigate("/profile/blocklist"),
                    icon: <Prohibit className="text-grey-700 w-5 h-5" />,
                    access: [ADMIN, OWNER],
                    id: 3,
                  },
                  {
                    title: "Sign out",
                    value: "logout",
                    onclick: clearUser,
                    icon: <SignOut className="text-grey-700 w-5 h-5" />,
                    access: [ADMIN, OWNER, MEMBER],
                    id: 4,
                  },
                ],
              }}
              showcaret={true}
            >
              <div className="flex items-center gap-3 cursor-pointer">
                <Avatar
                  name={`${user?.first_name} ${user?.last_name}`}
                  mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
                    user?.company_uuid || user?.uuid
                  }/profile/${user?.profile_pic}`}
                />

                <div className="flex flex-col gap-1">
                  <div className="flex items-center text-sm font-medium	 text-white">
                    {NG_CURRENCY_SYMBOL}
                    {walletDetails?.accountBalance || 0}
                  </div>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
        {showWelcomeModal ? (
          <Modal
            handleClose={() => handleCloseWelcomeModal()}
            headerComponent={null}
            footerComponent={null}
            shouldCloseOnClickOutside={true}
          >
            <WelcomeFonuModal
              handleCloseWelcomeModal={handleCloseWelcomeModal}
            />
          </Modal>
        ) : null}
        <PhoneSystem isDialpadOpen={isDialpadOpen} openDialpad={openDialpad} />
      </div>
    </>
  );
}
