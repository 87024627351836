import {
  ArrowsLeftRight,
  Chair,
  Crown,
  DeviceTabletCamera,
  GroupUser,
  User,
  MusicNote,
  PhoneCall,
  Star,
  VinylRecord,
  Arrow,
  Voicemail,
} from 'assets/images';
import { toastEmitter } from 'components/Toast';
import { Howl } from 'howler';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import moment from 'moment';

const synthesis = window?.speechSynthesis;

const INCOMING_RING = 'incomingRing.ogg';
const OUTGOING_RING = 'outgoingRing.mp3';

export const textToSpeechRegx = /^[a-zA-Z0-9,?.'" ]*$/;
export const alphaNumericRegx = /^[a-zA-Z0-9_ ]*$/;
export const numericRegx = /^[0-9]*$/;
export const passwordRegx =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const IPRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(?:(?:[0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|(?:[0-9a-fA-F]{1,4}:){1,7}:|(?:[0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|(?:[0-9a-fA-F]{1,4}:){1,5}(?::[0-9a-fA-F]{1,4}){1,2}|(?:[0-9a-fA-F]{1,4}:){1,4}(?::[0-9a-fA-F]{1,4}){1,3}|(?:[0-9a-fA-F]{1,4}:){1,3}(?::[0-9a-fA-F]{1,4}){1,4}|(?:[0-9a-fA-F]{1,4}:){1,2}(?::[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:(?:(?::[0-9a-fA-F]{1,4}){1,6})?|:(?:(?::[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(?::[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(?:ffff(?::0{1,4}){0,1}:){0,1}(?:(?:25[0-5]|(?:2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(?:25[0-5]|(?:2[0-4]|1{0,1}[0-9]){0,1}[0-9])|(?:[0-9a-fA-F]{1,4}:){1,4}:(?:(?:25[0-5]|(?:2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(?:25[0-5]|(?:2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

export const getRequiredText = (field) => {
  return `${field} is required.`;
};
export const MONTHLY = 'MONTHLY';
export const ANNUALLY = 'ANNUALLY';
export const DAILY = 'DAILY';

export const ADMIN = 4;
export const OWNER = 2;
export const MEMBER = 3;

export const prefix = '+234';

export const NG_CURRENCY_SYMBOL = '₦';

export const PAY_WITH_CARD = 'pay_with_card';

export function getToken() {
  return localStorage.getItem('fonu-acc-tkn') || null;
}

export const rolesArray = [
  { id: 2, label: 'Admin', value: 4 },
  { id: 3, label: 'Owner', value: 2 },
  { id: 1, label: 'Member', value: 3 },
];

export const roleIconLookup = {
  4: <Chair />,
  2: <Crown />,
  3: <Star />,
};

export const IncomingRing = new Howl({
  src: [`${window.location.origin}/${INCOMING_RING}`],
  loop: true,
});

export const OutgoigRing = new Howl({
  src: [`${window.location.origin}/${OUTGOING_RING}`],
  loop: true,
});

export const navigationArr = [
  {
    id: 1,
    path: '/overview',
    parent: 'overview',
    label: 'Overview',
    access: [ADMIN, OWNER, MEMBER],
  },
  // {
  //   id: 2,
  //   path: '/inbox',
  //   parent: 'inbox',
  //   label: 'Inbox',
  //   access: [ADMIN, OWNER, MEMBER],
  // },
  {
    id: 3,
    path: '/phone-numbers/active-numbers',
    label: 'Phone Numbers',
    parent: 'phone-numbers',
    access: [ADMIN, OWNER],
    innerNavigation: [
      {
        id: 1,
        path: '/phone-numbers/active-numbers',
        label: 'Active Numbers',
        icon: PhoneCall,
        access: [ADMIN, OWNER],
      },
      {
        id: 2,
        path: '/phone-numbers/port-number',
        label: 'Port existing number',
        icon: Arrow,
        access: [ADMIN, OWNER],
      },
    ],
  },
  {
    id: 4,
    path: '/calls/details',
    parent: 'calls',
    label: 'Calls',
    access: [ADMIN, OWNER, MEMBER],
    innerNavigation: [
      {
        id: 1,
        path: '/calls/details',
        label: 'Call details',
        icon: PhoneCall,
        access: [ADMIN, OWNER, MEMBER],
      },
      {
        id: 2,
        path: '/calls/recordings',
        label: 'Call recordings',
        icon: VinylRecord,
        access: [ADMIN, OWNER, MEMBER],
      },
      {
        id: 3,
        path: '/calls/voicemails',
        label: 'Voicemails',
        icon: Voicemail,
        access: [ADMIN, OWNER, MEMBER],
      },
      {
        id: 4,
        path: '/calls/forwarding',
        label: 'Set forwarding',
        icon: ArrowsLeftRight,
        access: [ADMIN, OWNER],
      },
      {
        id: 5,
        path: '/calls/greetings',
        label: 'Custom greetings',
        icon: MusicNote,
        access: [ADMIN, OWNER],
      },
      {
        id: 6,
        path: '/calls/phone-menu',
        label: 'Phone menu',
        icon: DeviceTabletCamera,
        access: [ADMIN, OWNER],
      },
    ],
  },
  // {
  //   id: 5,
  //   path: '/sms',
  //   parent: 'sms',
  //   label: 'SMS',
  //   access: [ADMIN, OWNER],
  // },
  {
    id: 6,
    path: '/bundle/purchase',
    parent: 'bundle',
    label: 'Bundle',
    access: [ADMIN, OWNER],
    innerNavigation: [
      {
        id: 1,
        path: '/bundle/purchase',
        label: 'Bundle',
        icon: User,
        access: [ADMIN, OWNER],
      },
      {
        id: 2,
        path: '/bundle/data',
        label: 'Data',
        icon: GroupUser,
        access: [ADMIN, OWNER],
      },
    ],
  },
  {
    id: 7,
    path: '/teams/members',
    parent: 'teams',
    label: 'Teams',
    access: [ADMIN, OWNER, MEMBER],
    innerNavigation: [
      {
        id: 1,
        path: '/teams/members',
        label: 'Members',
        icon: User,
        access: [ADMIN, OWNER, MEMBER],
      },
      {
        id: 2,
        path: '/teams/all-teams',
        label: 'Teams',
        icon: GroupUser,
        access: [ADMIN, OWNER, MEMBER],
      },
    ],
  },
  // {
  //   id: 8,
  //   path: '/campaign',
  //   parent: 'campaign',
  //   label: 'Campaign',
  //   access: [ADMIN, OWNER],
  // },
  // {
  //   id: 9,
  //   path: '/report',
  //   parent: 'report',
  //   label: 'Report',
  //   access: [ADMIN, OWNER],
  // },
  // {
  //   id: 10,
  //   path: '/automations',
  //   parent: 'automations',
  //   label: 'Automations',
  //   access: [ADMIN, OWNER],
  // },
  {
    id: 11,
    path: '/KYC/customer-kyc',
    parent: 'KYC',
    label: 'KYC verification',
    access: [ADMIN, OWNER],
    innerNavigation: [
      {
        id: 1,
        path: '/KYC/customer-kyc',
        label: 'Customer KYC',
        icon: User,
        access: [ADMIN, OWNER],
      },
      {
        id: 2,
        path: '/KYC/kyc-details',
        label: 'Reports',
        icon: GroupUser,
        access: [ADMIN, OWNER],
      },
    ],
  },
  {
    id: 12,
    path: '/contacts',
    parent: 'contacts',
    label: 'Contacts',
    access: [ADMIN, OWNER, MEMBER],
  },
];

export const stringToColour = (str) => {
  if (!str || !str.length) return;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const darkenColor = (hexColor, factor = 20) => {
  if (!hexColor) return '#fff';
  // Remove the '#' character if present
  hexColor = hexColor.replace('#', '');

  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Darken the color by reducing the RGB values
  const newR = Math.max(0, r - factor);
  const newG = Math.max(0, g - factor);
  const newB = Math.max(0, b - factor);

  // Convert the darkened RGB back to hex
  const darkenedColor = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

  return darkenedColor;
};

export const lightenColor = (hexColor, factor = 20) => {
  if (!hexColor) return '#fff';

  // Remove the '#' character if present
  hexColor = hexColor.replace('#', '');

  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Lighten the color by increasing the RGB values
  const newR = Math.min(255, r + factor);
  const newG = Math.min(255, g + factor);
  const newB = Math.min(255, b + factor);

  // Convert the lightened RGB back to hex
  const lightenedColor = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

  return lightenedColor;
};

export const formatPhoneNumber = (number) => {
  if (!number || typeof number === 'object') return;
  const phoneNumber = parsePhoneNumber(`${number.replace(/\s/g, '')}`);
  return phoneNumber?.formatInternational() ?? number?.replace('+', '');
};

export const ayt = (number) => {
  return new AsYouType().input(number);
};

export const formatDateTime = (timestring, utc = true) => {
  if (!timestring) return '---';
  if (utc) {
    const utcMoment = moment.utc(timestring);
    const localMoment = utcMoment.local();
    let timeStrings = localMoment?.format();
    if (!timeStrings) return '---';
    return moment(timeStrings).format('MMM DD, YYYY&hh:mm A')?.split('&');
  }
  return moment(timestring).format('MMM DD, YYYY&hh:mm A')?.split('&');
};

export const ForwardingArray = [
  { id: 1, label: 'Teams', value: 'TEAM' },
  { id: 2, label: 'Members', value: 'EXTENSION' },
  { id: 3, label: 'Phone number', value: 'NUMBER' },
  { id: 4, label: 'Fonu number', value: 'FONU_NUMBER' },
  { id: 5, label: 'Hangup', value: 'HANGUP' },
  { id: 6, label: 'Voicemail', value: 'VOICEMAIL' },
  { id: 7, label: 'Phone menu', value: 'IVR' },
  { id: 8, label: 'SIP Address', value: 'IP' },
];

export const parseJwt = (token) => {
  if (!token) return;
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const handleSpeakText = (text = '') => {
  if (!text) return;
  if ('speechSynthesis' in window) {
    const utterance = new SpeechSynthesisUtterance(text);
    const voices = synthesis.getVoices();
    utterance.lang = 'en';
    utterance.voice = voices?.[2];
    synthesis.speak(utterance);
  } else {
    toastEmitter(
      'error',
      'Speak feature is not supported in your browser. Please use a modern browser that supports this feature.',
    );
  }
};

export const convertTextToSpeechFile = (text = '') => {
  if (!text) return;
};

export const downloadFile = (file, isUrl = false) => {
  if (!file) return;
  const element = document.createElement('a');
  element.setAttribute('href', isUrl ? file : URL.createObjectURL(file));
  element.setAttribute('download', isUrl ? 'voicemail' : file?.name ?? '');
  element.setAttribute('target', '_blank');
  element.click();
};

export const secondsToHHMMSS = (seconds = 0) => {
  if (!seconds) return '00:00';
  return moment.utc(seconds * 1000).format('mm:ss');
};
export const SecondsTohhmmss = (totalSeconds) => {
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = totalSeconds - hours * 3600 - minutes * 60;
  // round seconds
  seconds = Math.round(seconds * 100) / 100;
  let result = hours === 0 ? '' : hours < 10 ? '0' + hours + ':' : hours + ':';
  result += minutes < 10 ? '0' + minutes : minutes;
  result += ':' + (seconds < 10 ? '0' + seconds : seconds);
  return result;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const wantToSendMessage = (e) => {
  if (!e) return false;
  if (
    e?.type === 'click' ||
    (e.type === 'keydown' &&
      e?.key === 'Enter' &&
      !(e?.ctrlKey || e?.shiftKey || e?.altKey))
  ) {
    return true;
  } else {
    return false;
  }
};

export function base64toFile(base64Data, fileName = 'selfie.png') {
  if (!base64Data) return;
  // Split the base64 string to get the content type and the actual base64 content
  const base64Parts = base64Data.split(';base64,');
  const contentType = base64Parts[0].split(':')[1];
  const raw = window.atob(base64Parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([uInt8Array], { type: contentType });

  // Create a File from the Blob
  const file = new File([blob], fileName, { type: contentType });

  return file;
}

export function sumOfTwoDigits(digit1, digit2) {
  // Check if the inputs are valid numbers
  if (typeof digit1 !== 'number' || typeof digit2 !== 'number') {
    return 'Please provide valid numbers.';
  }

  // Calculate the sum of the two digits
  let sum = digit1 + digit2;

  // Return the result
  return sum?.toFixed(2) ?? sum;
}
