export { ReactComponent as DotsNine } from './DotsNine.svg';
export { ReactComponent as NotificationBell } from './NotificationBell.svg';
export { ReactComponent as Hash } from './Hash.svg';
export { ReactComponent as PhoneCall } from './PhoneCall.svg';
export { ReactComponent as PhoneIncoming } from './PhoneIncoming.svg';
export { ReactComponent as PhoneOutgoing } from './PhoneOutgoing.svg';
export { ReactComponent as Refresh } from './Refresh.svg';
export { ReactComponent as Chevron } from './Chevron.svg';
export { ReactComponent as Check } from './Check.svg';
export { ReactComponent as Download } from './Download.svg';
export { ReactComponent as Pause } from './Pause.svg';
export { ReactComponent as Play } from './Play.svg';
export { ReactComponent as Speaker } from './Speaker.svg';
export { ReactComponent as X } from './X.svg';
export { ReactComponent as Microphone } from './Microphone.svg';
export { ReactComponent as Search } from './Search.svg';
export { ReactComponent as Filter } from './Filter.svg';
export { ReactComponent as Arrow } from './Arrow.svg';
export { ReactComponent as Chair } from './Chair.svg';
export { ReactComponent as Star } from './Star.svg';
export { ReactComponent as Crown } from './Crown.svg';
export { ReactComponent as MusicNote } from './MusicNote.svg';
export { ReactComponent as MusicFill } from './MusicFill.svg';
export { ReactComponent as VinylRecord } from './VinylRecord.svg';
export { ReactComponent as Voicemail } from './Voicemail.svg';
export { ReactComponent as DeviceTabletCamera } from './DeviceTabletCamera.svg';
export { ReactComponent as ShoppingCart } from './ShoppingCart.svg';
export { ReactComponent as ArrowsLeftRight } from './ArrowsLeftRight.svg';
export { ReactComponent as Backspace } from './Backspace.svg';
export { ReactComponent as Trash } from './Trash.svg';
export { ReactComponent as Edit } from './Edit.svg';
export { ReactComponent as Mail } from './Mail.svg';
export { ReactComponent as GroupUser } from './GroupUser.svg';
export { ReactComponent as Mobile } from './Mobile.svg';
export { ReactComponent as User } from './User.svg';
export { ReactComponent as RemoveForwarding } from './RemoveForwarding.svg';
export { ReactComponent as Setting } from './Setting.svg';
export { ReactComponent as CloudArrowUp } from './CloudArrowUp.svg';
export { ReactComponent as FileText } from './FileText.svg';
export { ReactComponent as Lightning } from './Lightning.svg';
export { ReactComponent as LightningFill } from './LightningFill.svg';
export { ReactComponent as AddUser } from './AddUser.svg';
export { ReactComponent as RemoveUser } from './RemoveUser.svg';
export { ReactComponent as PageNotFoundImage } from './404.svg';
export { ReactComponent as ProfileImage } from './ProfileImage.svg';
export { ReactComponent as Status } from './Status.svg';
export { ReactComponent as LogoBlack } from './LogoBlack.svg';
export { ReactComponent as LogoFullWhite } from './LogoFullWhite.svg';
export { ReactComponent as NoRecordPhone } from './noRecordPhone.svg';
export { ReactComponent as Confrance } from './Confrance.svg';
export { ReactComponent as RadioFill } from './RadioFill.svg';
export { ReactComponent as Radio } from './Radio.svg';
export { ReactComponent as PreviousDoubleLeft } from './PreviousDoubleLeft.svg';
export { ReactComponent as PreviousDoubleRight } from './PreviousDoubleRight.svg';
export { ReactComponent as PreviousLeft } from './PreviousLeft.svg';
export { ReactComponent as PreviousRight } from './PreviousRight.svg';
export { ReactComponent as SortingIcon } from './SortingIcon.svg';
export { ReactComponent as EyeClose } from './eyeClose.svg';
export { ReactComponent as EyeOpen } from './eyeOpen.svg';
export { default as Call3D } from './Call3D.png';
export { default as Mastercard } from './Mastercard.png';
export { default as visa } from './visa.png';
export { default as Instagram } from './Instagram.png';
export { ReactComponent as NoRecord } from './NoRecord.svg';
export { ReactComponent as Support } from './Support.svg';
export { ReactComponent as InfoGrey } from './infoGrey.svg';
export { ReactComponent as PortPhoneIcon } from './PortPhoneIcon.svg';
export { ReactComponent as MissedCall } from './MissedCall.svg';
export { ReactComponent as PaperPlaneTilt } from './PaperPlaneTilt.svg';
export { ReactComponent as Smiley } from './Smiley.svg';
export { ReactComponent as Paperclip } from './Paperclip.svg';
export { ReactComponent as Prohibit } from './Prohibit.svg';
export { ReactComponent as UserList } from './UserList.svg';
export { ReactComponent as TwitterIcon } from './TwitterIcon.svg';
export { ReactComponent as UserCircle } from './UserCircle.svg';
export { ReactComponent as Card } from './Card.svg';
export { ReactComponent as SignOut } from './SignOut.svg';
export { ReactComponent as Plus } from './Plus.svg';
export { ReactComponent as Minus } from './Minus.svg';
export { ReactComponent as Password } from './Password.svg';
export { ReactComponent as GeneralFileIcon } from './GeneralFileIcon.svg';
export { ReactComponent as ExcelFileIcon } from './ExcelFileIcon.svg';
export { ReactComponent as WordFileIcon } from './WordFileIcon.svg';
export { ReactComponent as PDFIcon } from './PDFIcon.svg';
export { ReactComponent as TextFileIcon } from './TextFileIcon.svg';
export { ReactComponent as CreditCard } from './CreditCard.svg';
export { ReactComponent as Camera } from './Camera.svg';
export { ReactComponent as PhoneMenuSvg } from './PhoneMenu.svg';
export { ReactComponent as PinLink } from './PinLink.svg';
export { ReactComponent as Billing } from './Billing.svg';
export { ReactComponent as FilledCard } from './FilledCard.svg';
export { ReactComponent as Passport } from './Passport.svg';
export { ReactComponent as DriverLicense } from './DriverLicense.svg';
export { ReactComponent as IdentyNumber } from './IdentyNumber.svg';
export { ReactComponent as WaveOne } from './WaveOne.svg';
export { ReactComponent as WaveTwo } from './WaveTwo.svg';
export { ReactComponent as Gift } from './Gift.svg';
export { ReactComponent as Renew } from './Renew.svg';
export { ReactComponent as StepKYC } from './StepKYC.svg';
export { ReactComponent as CloudFile } from './CloudFile.svg';
export { ReactComponent as Clock } from './Clock.svg';
export { ReactComponent as Money } from './Money.svg';
export { ReactComponent as Wallet } from './Wallet.svg';
export { ReactComponent as Capture } from './Capture.svg';
export { default as WhatsappIcon } from './WhatsappIcon.png';
export { ReactComponent as XIcon } from './XIcon.svg';
export { default as InstagramIcon } from './InstagramIcon.png';
export { default as Paystack } from './Paystack.svg';
export { default as Mono } from './Mono.svg';

// current color end
export { ReactComponent as WhiteRecord } from './NewDailer/WhiteRecord.svg';
export { ReactComponent as WhiteHold } from './NewDailer/WhiteHold.svg';
export { ReactComponent as WhiteMute } from './NewDailer/WhiteMute.svg';
export { ReactComponent as BlackAdd } from './NewDailer/BlackAdd.svg';
export { ReactComponent as BlackRecord } from './NewDailer/BlackRecord.svg';
export { ReactComponent as BlackHold } from './NewDailer/BlackHold.svg';
export { ReactComponent as BlackKeypad } from './NewDailer/BlackKeypad.svg';
export { ReactComponent as BlackTransfer } from './NewDailer/BlackTransfer.svg';
export { ReactComponent as BlackMute } from './NewDailer/BlackMute.svg';
// dialer icon

export { ReactComponent as DialerCall } from './DialerCall.svg';
export { ReactComponent as CutCall } from './CutCall.svg';
export { ReactComponent as UnHold } from './UnHold.svg';
export { ReactComponent as UnAddCall } from './UnAddCall.svg';
export { ReactComponent as UnDialpad } from './UnDialpad.svg';
export { ReactComponent as UnRecord } from './UnRecord.svg';
export { ReactComponent as UnTransfer } from './UnTransfer.svg';
export { ReactComponent as UnMute } from './UnMute.svg';
export { ReactComponent as RecordActive } from './Dialer/RecordActive.svg';
export { ReactComponent as MuteActive } from './Dialer/MuteActive.svg';
export { ReactComponent as TransferActive } from './Dialer/TransferActive.svg';

export { ReactComponent as MenuIcon } from './MenuIcon.svg';
export { ReactComponent as EndCallDark } from './Dialer/EndCall.svg';
export { ReactComponent as HoldDark } from './Dialer/Hold.svg';
export { ReactComponent as KeypadDark } from './Dialer/Keypad.svg';
export { ReactComponent as MuteDark } from './Dialer/Mute.svg';
export { ReactComponent as MuteGreen } from './Dialer/MuteGreen.svg';
export { ReactComponent as UnholdLight } from './Dialer/UnholdLight.svg';
export { ReactComponent as KeypadLight } from './Dialer/KeypadLight.svg';
export { ReactComponent as UnmuteLight } from './Dialer/UnmuteLight.svg';

// dialer icon
