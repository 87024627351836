import { SidebarLayout } from 'layouts';
import {
  Automations,
  Bundle,
  CallRecordings,
  Calls,
  Campaign,
  Contacts,
  Dashboard,
  Forwarding,
  Greetings,
  Inbox,
  Members,
  PageNotFound,
  PaymentVerification,
  MonoPaymentVerification,
  PhoneMenu,
  PhoneNumbers,
  PortNumber,
  PortNumberStatus,
  Report,
  SMS,
  Teams,
  Unauthorized,
  Verification,
  Voicemail,
} from 'pages';
import Cart from 'pages/PhoneNumbers/Cart';
import PortNumberForm from 'pages/PortNumber/PortNumberForm';
import PortNumberListing from 'pages/PortNumber/PortNumberListing';
import Profile from 'pages/Profile';
import Blocklist from 'pages/Profile/Blocklist';
import Pnb from 'pages/Profile/Pnb';
import ChatList from 'pages/SMS/Components/ChatList';
import ChatViewArea from 'pages/SMS/Components/ChatViewArea';
import Layout from 'pages/SMS/Layout';
import ProfileLayout from 'pages/Profile/Layout';
import { lazy } from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import { ADMIN, MEMBER, OWNER } from 'shared/resources';
import ForgotPassword from 'pages/ForgotPassword';
import VerificationLayout from 'layouts/VerificationLayout';
import ChangePassword from 'pages/Profile/ChangePassword';
import TermsNConditions from 'pages/TermsNConditions';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import { Transactions } from 'pages/Transactions';
import { SavedCards } from 'pages/SavedCards';
import { InviteAndEarn } from 'pages/Profile/InviteAndEarn';
import { KYC } from 'pages/KYC';
import KYCReadView from 'pages/KYC/KYCReadView';
import { BundleData } from 'pages/Bundle/BundleData';
import { KycReports } from 'pages/KYC/KycReports';
import Wallet from 'pages/Wallet';
import WalletPaymentVerification from 'pages/WalletPaymentVerification';
import MonoWalletPaymentVerification from 'pages/MonoWalletPaymentVerification';

const Login = lazy(() => import('pages/Login'));
const Signup = lazy(() => import('pages/Signup'));
const AuthRemover = lazy(() => import('auth/AuthRemover'));
const AuthProvider = lazy(() => import('auth/AuthProvider'));
const AuthLayout = lazy(() => import('layouts/AuthLayout'));
const Access = lazy(() => import('auth/Access'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthRemover>
        <Outlet />
      </AuthRemover>
    ),
    children: [
      {
        index: true,
        element: <Login />,
        id: 'Login',
      },
      {
        path: 'signup',
        element: <Signup />,
        id: 'Signup',
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
        id: 'ForgotPassword',
      },
      {
        path: 'term-n-conditions',
        element: <TermsNConditions />,
        id: 'TermsAndConditions',
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
        id: 'PrivacyPolicy',
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthProvider>
        <AuthLayout />
      </AuthProvider>
    ),
    children: [
      {
        path: 'mono-payment-verification',
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <MonoPaymentVerification />
          </Access>
        ),
        id: 'MonoPaymentVerification',
      },
      {
        path: 'payment-verification',
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <PaymentVerification />
          </Access>
        ),
        id: 'PaymentVerification',
      },
      {
        path: 'mono-wallet-payment-verification',
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <MonoWalletPaymentVerification />
          </Access>
        ),
        id: 'MonoWalletPaymentVerification',
      },
      {
        path: 'wallet-payment-verification',
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <WalletPaymentVerification />
          </Access>
        ),
        id: 'WalletPaymentVerification',
      },

      {
        path: 'overview',
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <Dashboard />
          </Access>
        ),
        id: 'Overview',
      },
      {
        path: 'profile',
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <ProfileLayout />
          </Access>
        ),
        children: [
          {
            path: 'general',
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <Profile />
              </Access>
            ),
          },
          {
            path: 'pnb',
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Pnb />
              </Access>
            ),
          },
          {
            path: 'invite-earn',
            element: (
              <Access allowedRoles={[OWNER, ADMIN, MEMBER]}>
                <InviteAndEarn />
              </Access>
            ),
          },
          {
            path: 'transactions',
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Transactions />
              </Access>
            ),
          },
          {
            path: 'saved-cards',
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <SavedCards />
              </Access>
            ),
          },
          {
            path: 'wallet',
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Wallet />
              </Access>
            ),
          },
          {
            path: 'blocklist',
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Blocklist />
              </Access>
            ),
          },
          {
            path: 'password',
            element: (
              <Access allowedRoles={[OWNER, ADMIN, MEMBER]}>
                <ChangePassword />
              </Access>
            ),
          },
        ],
        id: 'Profile',
      },

      {
        path: 'inbox',
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <Inbox />
          </Access>
        ),
        id: 'Inbox',
      },
      {
        path: 'phone-numbers',
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <SidebarLayout />
          </Access>
        ),
        id: 'Phone',
        children: [
          {
            path: 'active-numbers',
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <PhoneNumbers />
              </Access>
            ),
          },
          {
            id: 'PortNumber',
            path: 'port-number',
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <PortNumber />
              </Access>
            ),
            children: [
              {
                index: true,
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <PortNumberListing />
                  </Access>
                ),
              },
              {
                path: 'form',
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <PortNumberForm />
                  </Access>
                ),
              },

              {
                path: 'status',
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <PortNumberStatus />
                  </Access>
                ),
              },
            ],
          },
          {
            path: 'cart',
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Cart />
              </Access>
            ),
          },
        ],
      },
      {
        path: 'calls',
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <SidebarLayout />
          </Access>
        ),
        id: 'Calls',
        children: [
          {
            id: 'call-details',
            path: 'details',
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <Calls />
              </Access>
            ),
          },
          {
            id: 'call-recordings',
            path: 'recordings',
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <CallRecordings />
              </Access>
            ),
          },
          {
            id: 'voicemails',
            path: 'voicemails',
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <Voicemail />
              </Access>
            ),
          },
          {
            id: 'forwarding',
            path: 'forwarding',
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Forwarding />
              </Access>
            ),
          },
          {
            id: 'greetings',
            path: 'greetings',
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Greetings />
              </Access>
            ),
          },
          {
            id: 'phone-menu',
            path: 'phone-menu',
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <PhoneMenu />
              </Access>
            ),
          },
        ],
      },
      {
        path: 'sms',
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <Layout />
          </Access>
        ),
        children: [
          { index: true, element: <SMS /> },
          {
            path: ':number',
            element: <ChatList />,
            children: [
              {
                index: true,
                element: <ChatViewArea />,
              },
              {
                path: ':id',
                element: <ChatViewArea />,
              },
            ],
          },
        ],
        id: 'sms',
      },
      {
        path: 'bundle',
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <SidebarLayout />
          </Access>
        ),
        children: [
          {
            path: 'purchase',
            element: <Bundle />,
          },
          {
            path: 'data',
            element: <BundleData />,
          },
        ],
        id: 'Bundle',
      },
      {
        path: 'teams',
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <SidebarLayout />
          </Access>
        ),
        id: 'teams',
        children: [
          {
            path: 'all-teams',
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <Teams />
              </Access>
            ),
          },
          {
            id: 'Members',
            path: 'members',
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <Members />
              </Access>
            ),
          },
        ],
      },
      {
        path: 'campaign',
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <Campaign />
          </Access>
        ),
        id: 'campaign',
      },
      {
        path: 'report',
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <Report />
          </Access>
        ),
        id: 'report',
      },
      {
        path: 'automations',
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <Automations />
          </Access>
        ),
        id: 'automations',
      },
      {
        path: 'contacts',
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <Contacts />
          </Access>
        ),
        id: 'contacts',
      },
      {
        path: 'KYC',
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <SidebarLayout />
          </Access>
        ),
        children: [
          {
            path: 'customer-kyc',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <KYC />,
              },
              {
                path: 'read-view',
                element: <KYCReadView />,
              },
            ],
          },
          {
            path: 'kyc-details',
            element: <KycReports />,
          },
        ],
        id: 'KYC',
      },
    ],
  },
  {
    path: '/account-verification/:token',
    element: <VerificationLayout />,
    children: [{ index: true, element: <Verification /> }],
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);
